import Button from "@/components/button";
import Input from "@/components/input";
import Categorized from "@/components/categorized";
import ExtLink from "@/components/ext-link";
import Info from "@/components/info";
import RestoreBtn from "@/components/restore-btn";
import SetStatus from "@/containers/set-status";
import {mapGetters} from "vuex";
import axios from "@/axios";

export default {
    name: "neo-green-content",
    components: {
        "neo-restore-btn": RestoreBtn,
        "neo-info": Info,
        "neo-button": Button,
        "neo-input": Input,
        "neo-set-status": SetStatus,
        "neo-ext-link": ExtLink,
        "neo-categorized": Categorized,
    },
    props: ["content", "setStatus", "restore", "tab"],
    data() {
        return {
            controls: {
                status: this.content.status,
            },
            tempHolder: "",
        };
    },
    computed: {
        ...mapGetters(["getReadOnlyMode"]),
        getTitle() {
            switch (this.content.type) {
                case "volunteering_roles":
                    return "Volunteering Roles";
                case "wider_evidence_skills":
                    return "Wider Evidence of Skills";
                case "charitable_work":
                    return "Charitable Work";
                default:
                    break;
            }
        },
        updateDoc() {
            return {
                case_id: this.$store.getters.getCaseId,
                url: this.content.value,
                data_type: this.content.type,
                is_collapse: this.content.is_collapse || false,
                id: this.content._id,
            };
        },
    },
    mounted() {},
    methods: {
        popIrrelevantData() {
            delete this.content.confidence_score;
            this.$emit("dataChanges", {...this.content, status: null, tab: this.tab});
        },
        getEntityIcon(identifier) {
            let icondata = this.$store.getters.getIconsData.filter((elem) => elem.key == identifier);
            if (icondata.length > 0) return icondata[0].icon_data;
            else return require("@/assets/icons/documents.svg");
        },
        onStatChanges(status) {
            this.$emit("dataChanges", {...this.content, status});
        },
        startEdit() {
            this.content.editMode = true;
            this.tempHolder = this.content.value;
        },
        cancelSave() {
            this.content.editMode = false;
            this.content.value = this.tempHolder;
        },
        completeSave() {
            axios.put(`/add-green-content`, this.updateDoc).then((response) => {});
            this.content.editMode = false;
        },
    },
};
